import { Component } from 'react'
import { connect } from 'react-redux'
import { Text } from 'react-internationalization'

import { PaymentUtils } from './../../utils/PaymentUtils'
import { cartUtils } from './../../utils/cartUtils'
import Util from './../../utils/Util'
import IntercomUtils from './../../utils/IntercomUtils'
import ScreenUtils from './../../utils/ScreenUtils'
import StoreUtils from '../../utils/StoreUtils'

import { selectCart, editCart, deleteCart } from '../../store/actions/carts'
import { addTransactionHead } from '../../store/actions/transactions'
import { setToastMessage } from '../../store/actions/app'
import { loadingFalse, loadingTrue } from '../../store/actions/loading'

import { Button } from '@countr/ui'
import ConfirmationModal from './../generic/ConfirmationModal'
import PayOnAccountModal from './../generic/PayOnAccountModal'
import PayplazaModal from './Providers/PayplazaModal'
import CCVModal from './Providers/CCVModal'
import CustomPayment from './Providers/CustomPayment'
import CustomerModal from '../Cart/Header/CustomerModal'
import Giftcard from './Providers/Giftcard'
import ExcessAmountModal from './ExcessAmountModal'
import ExtraInfoModal from './../generic/ExtraInfoModal'

import { AppInstances } from '../../utils/countrSdkInstance'

import './NewPaymentModal.scss'
import { ConviousApiManager } from 'convious-api-manager'

const mapStateToProps = state => {
  return {
    app: state.app,
    payments: state.payments,
    carts: state.carts,
    devices: state.devices,
    settings: state.settings,
    employees: state.employees,
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setToastMessage: msg => dispatch(setToastMessage(msg)),
    selectCart: cart => dispatch(selectCart(cart)),
    editCart: cart => dispatch(editCart(cart)),
    deleteCart: id => dispatch(deleteCart(id)),
    addTransactionHead: transaction => dispatch(addTransactionHead(transaction)),
    loadingTrue: () => dispatch(loadingTrue()),
    loadingFalse: () => dispatch(loadingFalse())
  }
}

const MANUAL_CARD_PAYMENT = 'manual_card_payment'
const PAYPLAZA = 'payplaza'
const CCV = 'ccv'
const INVOICE = 'invoice'
const GIFTCARD = 'giftcard'
const CASH = 'cash'
const PAY_LATER = 'pay_later'
const OTHER = 'other'

const BTN_STYLE = {
  margin: 6,
  fontSize: 16
}

class PaymentSelectionEmbedded extends Component {
  state = {
    manualCardPaymentConfirmation: false,
    openCCVModal: false,
    openPayplazaModal: false,
    openCustomPaymentModal: false,
    customerInvoice: false,
    customerRequiredModal: false,
    openGiftcardModal: false,
    openExcessAmountModal: false,
    customMethod: {},
    reference: '',
    methodSelected: {},
    addTip: false,
    shouldRound: false,
    extraInfoModal: false,
    extraInfo: '',
    openCustomerModal: false,
    openPayOnAccount: false
  }

  // Manual card payment modal
  handleOpenManualCardPaymentConfirmation = () => {
    this.setState({ manualCardPaymentConfirmation: true })
  }

  handleCancelManualCardPaymentConfirmation = () => {
    this.setState({ manualCardPaymentConfirmation: false })
  }

  handleConfirmManualCardPaymentConfirmation = () => {
    this.handleCancelManualCardPaymentConfirmation()
    this.handlePayment(MANUAL_CARD_PAYMENT)
  }

  // CCV modal
  handleOpenCCVModal = () => {
    this.setState({ openCCVModal: true })
  }

  handleCloseCCVModal = resultCode => {
    this.setState({ openCCVModal: false })

    if (resultCode === 'AUTHORISED') {
      this.handlePayment(CCV)
    }
  }

  // Payplaza modal
  handleOpenPayplazaModal = () => {
    this.setState({ openPayplazaModal: true })
  }

  completePayplazaTransaction = resultCode => {
    if (resultCode === 'SUCCESS') {
      this.handlePayment(PAYPLAZA)
    }
  }

  handleClosePayplazaModal = resultCode => {
    this.setState({ openPayplazaModal: false })
  }

  // Custom payment with extras (Cikam)
  handleCustomPaymentWithExtras = method => {
    const reference =
      ((this.props.devices.store || {}).store_id || '0') +
      '-' +
      (this.props.devices.device.device_id || '0') +
      '-' +
      Math.round(new Date().getTime(), null)
    this.setState({
      openCustomPaymentModal: true,
      customMethod: {
        ...method,
        extraInfo: this.state.extraInfo
      },
      reference
    })
  }

  handleCloseCustomPaymentWithExtras = () => {
    this.setState({ openCustomPaymentModal: false, customMethod: {} })
  }

  handleClearCustomPaymentExtras = () => {
    this.setState({ extraInfo: '' })
  }

  handleResultCustomPayment = result => {
    if (!!result?.success) {
      const custom = { ...this.state.customMethod }
      this.handleCloseCustomPaymentWithExtras()
      this.handlePayment(custom.name, null, {
        ...result,
        extraInfo: this.state.extraInfo
      })
    }
  }

  // Customer modal handler
  handleOpenCustomerModal = methodSelected => {
    this.setState({ openCustomerModal: true, methodSelected: methodSelected })
  }

  closeCustomerModal = () => {
    this.setState({ openCustomerModal: false })
  }

  handleCloseCustomerModal = customer => {
    if (customer && customer.email) {
      this.setState({ openCustomerModal: false, customerInvoice: true }, () => {
        if (this.state.methodSelected.method === INVOICE) {
          this.handlePayment(INVOICE, customer)
        }
      })
    } else {
      this.closeCustomerModal()
    }
  }

  handleOpenCustomerRequiredModal = () => {
    this.setState({ customerRequiredModal: true })
  }

  handleOpenExtraInfoModal = methodSelected => {
    this.setState({ extraInfoModal: true, methodSelected })
  }

  handleCloseExtraInfoModal = value => {
    this.setState({ extraInfoModal: false })
    if (value) {
      this.setState({ extraInfo: value }, () =>
        this.handleContinuePaymentMethod(this.state.methodSelected)
      )
    }
  }

  handleCloseCustomerRequiredModal = () => {
    this.setState({ customerRequiredModal: false })
  }

  // Giftcard handler
  handleOpenGiftCard = () => {
    this.setState({ openGiftcardModal: true })
  }

  handleCloseGiftCard = () => {
    this.setState({ openGiftcardModal: false })
  }

  handleResultGiftcard = result => {
    this.handleCloseGiftCard()
    this.handlePayment(GIFTCARD, null, result)
  }

  //pay on account handler
  handleCancelPayOnAccount = () => {
    this.setState({ openPayOnAccount: false })
  }

  handleConfirmPayOnAccount = note => {
    this.handleCancelPayOnAccount()
    this.handlePayment(this.state.methodSelected.method, this.props.carts.selectedCart.customer, {
      payOnAccountNote: note
    })
  }

  // Payment handler
  handlePaymentMethod = async methodSelected => {
    let finalCartId = false
    if (this.props.carts.selectedCart.external_id) {
      finalCartId = await ConviousApiManager.checkOrCreateNewCart(
        this.props.carts.selectedCart.external_id,
        this.props.user.user.extras.external_account_name
      )
      if (finalCartId && finalCartId != this.props.carts.selectedCart.external_id) {
        let cartTmp = JSON.parse(JSON.stringify(this.props.carts.selectedCart))
        cartTmp.external_id = finalCartId

        this.props.selectCart(cartTmp)
        cartUtils.updateCartServer(cartTmp)
        cartUtils.updateCartLocally(cartTmp)
      }
    }

    if (finalCartId || !this.props.carts.selectedCart.external_id) {
      if (
        this.props.settings.roundCashPayment &&
        (methodSelected.method === CASH || methodSelected.usedForChange)
      ) {
        this.setState({ shouldRound: true }, () => this.handleRunPayment(methodSelected))
      } else {
        this.setState({ shouldRound: false }, () => this.handleRunPayment(methodSelected))
      }
    }
  }

  handleRunPayment = methodSelected => {
    if (this.props?.change > 0 && this.props.settings.askTips) {
      this.setState({ openExcessAmountModal: true, methodSelected: methodSelected })
      return
    }
    if (methodSelected.provider === OTHER && methodSelected.invoiceLater) {
      this.setState({ methodSelected: methodSelected })
      if (!this.props.carts.selectedCart.customer) {
        this.props.setToastMessage('no_customer_selected')
        this.setState({ openCustomerModal: true })
        return
      }
      const maxSingletransaction = this.props.carts.selectedCart.customer.payment_on_amount
        .max_amount_for_single_transaction
        ? this.props.carts.selectedCart.customer.payment_on_amount.max_amount_for_single_transaction
        : this.props.user.user.payment_on_amount.default_max_amount_for_single_transaction
      if (this.props.total > maxSingletransaction) {
        this.props.setToastMessage('maximum_amount_single_transaction_excedeed')
        return
      }
      this.setState({ openPayOnAccount: true })
      return
    }
    this.handleContinuePaymentMethod(methodSelected)
  }

  handleContinuePaymentMethod = methodSelected => {
    const method = { ...methodSelected }
    const {
      app,
      settings,
      devices: { store },
      carts,
      total,
      setToastMessage
    } = this.props

    if (isNaN(total)) {
      setToastMessage('invalid_amount')
      return
    }

    if (method.isCustom) {
      if (method.extras) {
        method.name = method.method
        method.method = method.provider
      }

      if (method.customer) {
        if (!cartUtils.hasCustomerCart(carts.selectedCart)) {
          this.handleOpenCustomerRequiredModal()
          return
        }
      }

      if (method.addExtraInformation && !this.state.extraInfo) {
        this.handleOpenExtraInfoModal(method)
        return
      }
    }

    // Blocking pin methods if has no internet connection
    if (!Util.isInternetOnline() && (method.method === CCV || method.method === PAYPLAZA)) {
      setToastMessage('no_connection_payment')
      return
    }

    IntercomUtils.trackEvent(`payment:method_${method.method}`)

    if (method.method === MANUAL_CARD_PAYMENT) {
      this.handleOpenManualCardPaymentConfirmation()
    } else if (method.method === CCV && !method.isCustom && app.isDesktop) {
      this.handleOpenCCVModal()
    } else if (method.method === PAYPLAZA) {
      this.handleOpenPayplazaModal()
    } else if (method.method === INVOICE) {
      this.handleOpenCustomerModal(method)
    } else if (method.method === GIFTCARD && settings.enableGiftcardScan) {
      this.handleOpenGiftCard()
    } else if (method.isCustom && method.extras) {
      this.handleCustomPaymentWithExtras(method)
    } else if (method.isCustom && ['cash', 'other'].indexOf(method.provider) >= 0) {
      this.handlePayment(method.method, null, { extraInfo: this.state.extraInfo })
    } else if (method.isCustom) {
      setToastMessage('error_loading_configuration')
      this.logError({
        msg: `PaymentSelectionEmbedded.handleContinuePaymentMethod could not load payment config`,
        stack: JSON.stringify(method)
      })
      return
    } else {
      this.handlePayment(method.method, null, { extraInfo: this.state.extraInfo })
    }
  }

  logError = async obj => {
    const errorObj = {
      message: `${obj.msg}, users: ${this.props.user.user.username},
         _ID: ${this.props.user.user._id}, device id: ${this.props.devices.device._id}`,
      user: this.props.user.user._id,
      store: this.props.devices.store._id,
      device: this.props.devices.device._id,
      stack: obj.stack,
      date: new Date().toISOString()
    }

    await AppInstances.getCountrSdk()
    AppInstances.logError(errorObj)
  }

  handlePayment = async (method, customer, extra) => {
    if (isNaN(this.props.total)) {
      this.props.setToastMessage('invalid_amount')
      return
    }

    this.props.loadingTrue()

    let cart = Object.assign({}, this.props.carts.selectedCart)

    let total = parseFloat(this.props.total)
    let change = parseFloat(this.props.change)
    const device = Object.assign({}, this.props.devices.device)
    const hasTables = StoreUtils.hasFloorplans(this.props.devices.store)

    if (extra?.__t?.toLowerCase() === GIFTCARD) {
      if (total > extra.value_remaining) {
        cart = PaymentUtils.addPartialGiftPaymentToCart(cart, extra)
        this.props.loadingFalse()
        this.props.handleFinishPaymentEmbedded(cart, cart.external_id)
        this.props.selectCart(cart)
        return
      } else {
        cart.info = { ...extra }
        cart.card_print_info = extra.title
      }
    } else if (!!extra) {
      cart = PaymentUtils.extractSdkPaymentExtras(cart, extra)

      if (!!extra.paid) {
        total = extra.paid
      }

      if (!!extra.change) {
        change = extra.change
      }
    }
    // Round payment check
    if (this.state.shouldRound) {
      const paid = Number(PaymentUtils.getLastCartPaymentValue(cart))

      if (cart.total - paid <= total) {
        total = PaymentUtils.roundCashPayment(total)
        cart.total = PaymentUtils.roundCashPayment(cart.total)
      } else {
        total = PaymentUtils.roundCashPayment(total)
      }
    }

    if (this.props.settings.enableCustomerScreen) {
      const port = this.props.settings.customerScreenPort
      if (port && port.length) {
        ScreenUtils.pay(total, change, cart.currency.code, port)
      }
    }

    const { kioskMode, showCartsListAlphabeticOrder } = this.props.settings
    const cartIndex = showCartsListAlphabeticOrder
      ? cartUtils
          .sortCartListAlphabetical(this.props.carts.carts)
          .findIndex(c => c._id === cart._id)
      : this.props.carts.carts.findIndex(c => c._id === cart._id)
    cart.employee = PaymentUtils.getEmployee(this.props.employees.selectedEmployee)
    if (customer) {
      cart.customer = customer
    }

    const callbacks = {
      editCart: this.props.editCart,
      selectCart: this.props.selectCart,
      deleteCart: this.props.deleteCart,
      addTransactionHead: this.props.addTransactionHead
    }

    const { paymentMethods } = this.props.payments
    const paymentMethod = paymentMethods.find(payment => payment.method === method)
    const provider = !!paymentMethod && !!paymentMethod.provider ? paymentMethod.provider : null
    const info = {}

    if (extra?.payOnAccountNote) {
      info.payOnAccountNote = extra.payOnAccountNote
    }

    const body = PaymentUtils.createTransactionBody(
      method,
      provider,
      cart,
      device,
      total,
      change,
      kioskMode,
      this.state.addTip,
      info,
      paymentMethod
    )

    if (cart.external_id) {
      const customer=cart.customer?{email: cart.customer.email}:undefined
      const result = await ConviousApiManager.createBooking(
        cart.external_id,
        this.props.user.user.extras.external_account_name,
        customer
      )
      body.extras.tickets = result.items
      body.extras.booking_id = result.id

      body.items.forEach(item => {
        if (item.product.options.open_url) {
          item.product.extras.qr_tag = result.items.flatMap(item => {
            return item.barcode.barcode
          })
        }
      })
    }

    PaymentUtils.payWithMethod(body, cart, callbacks, cartIndex, hasTables).then(
      result => {
        IntercomUtils.trackEvent('payment:payment_succeeded')
        this.props.loadingFalse()
        this.props.handleFinishPaymentEmbedded(body, cart.external_id)
      },
      error => {
        this.logError({
          msg: `PaymentUtils.payWithMethod method returned an error from the server`,
          stack: JSON.stringify(error)
        })
        this.props.loadingFalse()
        this.props.handleFinishPaymentEmbedded(body, cart.external_id)
      }
    )
  }

  shouldShowPaymentType = method => {
    if (
      method.provider === OTHER &&
      method.invoiceLater &&
      this.props.carts.selectedCart.customer &&
      (!this.props.carts.selectedCart.customer.payment_on_amount ||
        (this.props.carts.selectedCart.customer.payment_on_amount &&
          !this.props.carts.selectedCart.customer.payment_on_amount?.active))
    ) {
      return false
    }
    if (this.props.total < 0) {
      return method.method === 'cash' || method.isCustom
    }

    return method?.isDesktopOnly && !this.props.app.isDesktop ? false : true
  }

  handleDisableButton = () => {
    let result = false

    if (
      !this.props.settings.allowPartialPayment &&
      this.props.total < this.props.carts.selectedCart.total
    ) {
      result = true
    }
    return result
  }

  handleCloseExcessAmountModal = () => {
    this.setState({ openExcessAmountModal: false })
  }

  handleConfirmTip = value => {
    this.setState({ addTip: value, openExcessAmountModal: false }, () =>
      this.handleContinuePaymentMethod(this.state.methodSelected)
    )
  }

  render() {
    return (
      <>
        <div className="payment-btn-grid">
          {this.props.payments.paymentMethods.map(
            (method, i) =>
              method.enabled &&
              this.shouldShowPaymentType(method) && (
                <Button
                  key={`${i}_${method.method}`}
                  onClick={() => this.handlePaymentMethod(method)}
                  label={<Text id={method.method} />}
                  styles={BTN_STYLE}
                  disabled={this.handleDisableButton()}
                />
              )
          )}
        </div>
        {this.state.openPayOnAccount && (
          <PayOnAccountModal
            openConfirmation={this.state.openPayOnAccount}
            handleCloseConfirmation={this.handleCancelPayOnAccount}
            confirmBtn={this.handleConfirmPayOnAccount}
            confirmationTitle={<Text id="payment_on_account" />}
          />
        )}
        {this.state.manualCardPaymentConfirmation && (
          <ConfirmationModal
            openConfirmation={this.state.manualCardPaymentConfirmation}
            handleCloseConfirmation={this.handleCancelManualCardPaymentConfirmation}
            confirmBtn={this.handleConfirmManualCardPaymentConfirmation}
            confirmationTitle={<Text id="manual_card_payment" />}
            confirmationText={<Text id="did_card_payment_succeed" />}
            type="manual_card_payment"
          />
        )}
        {this.state.openCCVModal && (
          // TODO: Remove when new desktop version is launch
          <CCVModal
            openCCVModal={this.state.openCCVModal}
            handleCloseCCVModal={this.handleCloseCCVModal}
            currency={this.props.carts.selectedCart.currency.code}
            total={this.props.total}
            language={this.props.settings.language}
          />
        )}
        {this.state.openPayplazaModal && (
          <PayplazaModal
            openPayplazaModal={this.state.openPayplazaModal}
            handleClosePayplazaModal={this.handleClosePayplazaModal}
            completePayplazaTransaction={this.completePayplazaTransaction}
            type="PURCHASE"
            currency={this.props.carts.selectedCart.currency.code}
            totalToPay={this.props.total}
          />
        )}
        {this.state.openCustomerModal && (
          <CustomerModal
            openCustomerDialog={this.state.openCustomerModal}
            handleCloseCustomerDialog={this.handleCloseCustomerModal}
            isInvoicePayment={false}
          />
        )}
        {this.state.customerRequiredModal && (
          <ConfirmationModal
            openConfirmation={this.state.customerRequiredModal}
            handleCloseConfirmation={this.handleCloseCustomerRequiredModal}
            noCloseBtn={true}
            confirmBtn={this.handleCloseCustomerRequiredModal}
            confirmationTitle={<Text id="customer_required_title" />}
            confirmationText={<Text id="customer_required_text" />}
            type="customer_required"
          />
        )}
        {this.state.extraInfoModal && (
          <ExtraInfoModal
            openExtraInfo={this.state.extraInfoModal}
            handleClose={this.handleCloseExtraInfoModal}
            handleConfirm={this.handleCloseExtraInfoModal}
          />
        )}
        {this.state.openCustomPaymentModal && (
          <CustomPayment
            open={this.state.openCustomPaymentModal}
            handleResultPayment={this.handleResultCustomPayment}
            handleClose={this.handleCloseCustomPaymentWithExtras}
            method={this.state.customMethod}
            amount={this.props.total}
            currency={this.props.carts.selectedCart.currency}
            reference={this.state.reference}
            handleClearExtra={this.handleClearCustomPaymentExtras}
          />
        )}
        {this.state.openGiftcardModal && (
          <Giftcard
            open={this.state.openGiftcardModal}
            total={this.props.total}
            handleResult={this.handleResultGiftcard}
            handleClose={this.handleCloseGiftCard}
          />
        )}
        {this.state.openExcessAmountModal && (
          <ExcessAmountModal
            open={this.state.openExcessAmountModal}
            handleClose={this.handleCloseExcessAmountModal}
            handleConfirmTip={this.handleConfirmTip}
            cart={this.props.carts.selectedCart}
            amount={this.props.total}
          />
        )}
      </>
    )
  }
}

const PaymentSelectionEmbeddedConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSelectionEmbedded)
export default PaymentSelectionEmbeddedConnected
