import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Text } from 'react-internationalization'
import { Utils } from '@countr/utils'
import { Button, Divider, Input, Row, Column, FormatCurrency } from '@countr/ui'

import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PaymentSelectionEmbedded from './PaymentSelectionEmbedded'
import PartialPaymentModal from './PartialPaymentModal'
import { setToastMessage } from '../../store/actions/app'
import Util from '../../utils/Util'
import { cartUtils } from '../../utils/cartUtils'

import './PaymentModal.scss'

const PaymentModal = ({ open, handleClose, settings }) => {
  const dispatch = useDispatch()
  const setToast = useCallback((s, o) => dispatch(setToastMessage(s, o)), [dispatch])
  const cart = useSelector(state => state.carts.selectedCart)
  const device = useSelector(state => state.devices.device)
  const { skipTransactionModal, showSuggestions, askTips } = useSelector(state => state.settings)
  const theme = useSelector(state => state.app.theme)
  const style = {
    color: theme.colors.text,
    backgroundColor: theme.colors.body
  }
  const [total, setTotal] = useState(0)
  const [openPartial, setOpenPartial] = useState(false)
  const [perSeat, setPerSeat] = useState(false)
  const [totalMinusPaid, setTotalMinusPaid] = useState(0)

  const TIP_VALUES = [5, 10, 15, 20]

  const handleChangeTotal = value => {
    setTotal(cartUtils.roundValue(value, 2))
  }

  const getChange = () => {
    return total - totalMinusPaid
  }

  const showTransactionToast = transaction => {
    if (skipTransactionModal) {
      setToast('recovery_transaction_complete', {
        ref: `#${transaction.receipt_id}`
      })
    }
  }

  const handleFinishPayment = transaction => {
    showTransactionToast(transaction)
    handleClose(transaction)
  }

  const handleClosePartialPayment = () => {
    setOpenPartial(false)
  }

  const handleFinishPartialPayment = transaction => {
    showTransactionToast(transaction)
    setOpenPartial(false)
    handleClose(transaction)
    setPerSeat(false)
  }

  const handlePartialPayment = seats => {
    if (seats) {
      setPerSeat(seats)
    } else {
      setPerSeat(false)
    }

    setOpenPartial(true)
  }

  const handleAddTip = value => {
    const tipAmount = (value * cart.total) / 100
    const newTotal = cart.total + tipAmount

    handleChangeTotal(newTotal)
  }

  useEffect(() => {
    setTotalMinusPaid(cart.total - cart.paid)
    setTotal(cart.total - cart.paid)
  }, [cart.payments])

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          Util.handleModalDisableBackdrop(reason, handleClose)
        }}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown>
        <DialogTitle id="form-dialog-title" style={style}>
          <Row justifyContent="space-between">
            <Text id="payment" />
          </Row>
          {settings.allowPartialPayment && (
            <Grid container className="partial-container">
              {cartUtils.showSeats(cart, device) && (
                <Grid item xs={6} className="partial-left">
                  <Button
                    label={<Text id="partial_payment_seat" />}
                    className="primary"
                    onClick={() => handlePartialPayment(true)}
                    disabled={cartUtils.getIsPartialOptionAvailable('SEAT', cart)}
                  />
                </Grid>
              )}
              <Grid item xs={6} className="partial-right">
                <Button
                  label={<Text id="partial_payment_item" />}
                  className="primary"
                  onClick={() => handlePartialPayment()}
                  disabled={cartUtils.getIsPartialOptionAvailable('ITEM', cart)}
                />
              </Grid>
            </Grid>
          )}
        </DialogTitle>
        <DialogContent style={style}>
          <Column>
            <Row>
              <strong>
                <Text id="total_amount" />:{' '}
                {Utils.displayCurrency(cart.total, cart.currency.symbol)}
              </strong>
            </Row>
            {cart.total !== totalMinusPaid && (
              <Row className="paid-so-far">
                <span>
                  <Text id="paid_so_far" />:{' '}
                  <FormatCurrency
                    value={cart.total - totalMinusPaid}
                    currency={cart.currency.symbol}
                  />
                </span>
              </Row>
            )}
            <Divider />
            <Row>
              <Input
                type="number"
                label={<Text id="expected_amount" />}
                value={Number(total)}
                onChange={handleChangeTotal}
                inputAdornment={cart.currency.symbol}
              />
              <div className="change">
                <span>
                  <Text id="change" />: {Utils.displayCurrency(getChange(), cart.currency.symbol)}
                </span>
                <Divider />
              </div>
            </Row>
            {showSuggestions && (
              <>
                <Row>
                  <span>
                    <Text id="suggested_amount" />
                  </span>
                </Row>
                <Row>
                  {Utils.suggestedValues(totalMinusPaid).map(value => (
                    <Button
                      key={value}
                      label={Utils.displayCurrency(value, cart.currency.symbol)}
                      onClick={() => handleChangeTotal(value)}
                      className="neutro"
                    />
                  ))}
                </Row>
              </>
            )}
            {askTips && (
              <>
                <Row>
                  <span>
                    <Text id="suggested_tip" />
                  </span>
                </Row>
                <Row>
                  {TIP_VALUES.map(value => (
                    <Button
                      key={value}
                      label={`${value} %`}
                      onClick={() => handleAddTip(value)}
                      className="neutro percent-btn"
                    />
                  ))}
                </Row>
              </>
            )}
            <Row>
              <PaymentSelectionEmbedded
                handleFinishPaymentEmbedded={handleFinishPayment}
                total={total}
                change={getChange()}
              />
            </Row>
          </Column>
        </DialogContent>
        <DialogActions style={style}>
          <Button label={<Text id="cancel" />} onClick={handleClose} className="secondary" />
        </DialogActions>
      </Dialog>
      <PartialPaymentModal
        openPartialPaymentModal={openPartial}
        handleClosePartialPaymentModal={handleFinishPartialPayment}
        handleClosePartialPayment={handleClosePartialPayment}
        perSeat={perSeat}
      />
    </>
  )
}

export default PaymentModal
